import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, forkJoin  } from 'rxjs';
import { switchMap, catchError, map, concatMap } from 'rxjs/operators';

import { ActivityReportForSubmitModel, ActivityReportExtendedModel, WorkingPeriodResponce, CustomerNotificationsResponse, RecognizedActivityReportModel, ZipCodeModel } from '@shared/models';
import { ActivityReportExtended } from '@shared/factories';
import { UserService } from '../auth/user.service';
import { environment } from 'environments/environment';

interface ZipCodeResponseModel {
  zip_code: ZipCodeModel;
}

interface RecognizedTimeFramesResponseModel {
  recognized_working_period: RecognizedActivityReportModel;
}

@Injectable()
export class WorkingPeriodService {
  private get ASSIGNMENTS_API(): string { return `${environment.apiUrl}api/portal/v3/assignments`             };
  private get WP_DETAILS_API():  string { return `${environment.apiUrl}api/portal/v3/working_periods`         };
  private get WP_REWIEW_API():   string { return `${environment.apiUrl}api/portal/v3/working_periods_review`  };
  private get WP_EXPORTS_API():  string { return `${environment.apiUrl}api/portal/v3/working_periods_exports` };

  constructor (
    private http:        HttpClient,
    private userService: UserService
  ) { }

  updateWorkingPeriod(periodId: number, data, suf: string = '') {
    return this.http.put<{success: boolean, errors?:string[]}>(`${this.WP_REWIEW_API}/${periodId}/${suf}`, data)
    .pipe(
      switchMap((res) => {
        if (res.success) return of(res.success);
        else return throwError(res.errors.join('; '))
      })
    );
  }

  approveWorkingPeriod(periodId: number, data) {
    return this.updateWorkingPeriod(periodId, data, 'internal_approve');
  }

  releaseWorkingPeriod(periodId: number, data) {
    return this.updateWorkingPeriod(periodId, data, 'customer_release');
  }

  rejectWorkingPeriod(periodId: number, data) {
    return this.updateWorkingPeriod(periodId, data, 'customer_reject');
  }

  deleteWorkingPeriod(periodId: number, data) {
    return this.updateWorkingPeriod(periodId, data, 'internal_reject');
  }

  manuallyResolveWorkingPeriod(periodId: number, data = null): Observable<{} | never> {
    return this.http.put<{success:boolean, errors?:string[]}>(`${this.WP_EXPORTS_API}/${periodId}/resolve_manually`, data )
    .pipe(
      switchMap((responce) => {
        if (responce.success) return of({});
        else return throwError(responce.errors.join('; '));
      })
    );
  }

  submitWorkingPeriod(ar: ActivityReportForSubmitModel): Observable<ActivityReportExtended[] | never> {
    return this.http.post<{success: boolean, errors?:string[], working_period?:ActivityReportExtendedModel}[]>(this.WP_DETAILS_API, { working_periods:[ar] })
    .pipe(
      switchMap((res) => {
        if (Array.isArray(res) && !res.filter(ar => !ar.success).length) return of(res.map(ar => new ActivityReportExtended(ar.working_period)));
        else return throwError(res.filter(e => e.errors).map(e => e.errors.map((e: any) => e.title).join('; ')));
      })
    );
  }

  internalCheck(periodId: number, comment: string, angle: number = null): Observable<any> {
    let data: any = { working_period: { internal_employee_notes: comment }};
    if (angle) data.working_period.photo_tn_rotation_angle = angle;

    return this.http.put<{success:boolean, errors?:string[], working_period?:ActivityReportExtendedModel}>(`${this.WP_REWIEW_API}/${periodId}/checked_time`, data )
    .pipe(
      switchMap((responce) => {
        if (responce.success) return of({});
        else return throwError(responce.errors.join('; '));
      })
    );
  }

  getWorkingPeriodById(periodId: number): Observable<ActivityReportExtended> {
    let calls: any = [];
    calls.push(this.http.get<WorkingPeriodResponce>(`${this.WP_DETAILS_API}/${periodId}`));
    if (this.userService.isInternal) calls.push(this.http.get<CustomerNotificationsResponse>(`${this.WP_DETAILS_API}/${periodId}/customers_notifications`));
    else calls.push(of(null));

    return forkJoin(calls).pipe(
      concatMap((res: [WorkingPeriodResponce, CustomerNotificationsResponse]) => {
        let calls: [Observable<WorkingPeriodResponce>, Observable<CustomerNotificationsResponse>, Observable<any>?, Observable<any>?] = res.map(r => of(r)) as [Observable<WorkingPeriodResponce>, Observable<CustomerNotificationsResponse>];

        if (this.userService.isInternal && !res[0].working_period.archived_at) calls.push(this.http.get<ZipCodeResponseModel>(`${this.ASSIGNMENTS_API}/${res[0].working_period.assignment.id}/zip_code`).pipe(catchError(err => of(null))));
        else calls.push(of(null));

        if (this.userService.isInternal && res[0].working_period.attachment_url &&
                                          !res[0].working_period.archived_at) calls.push(this.http.get<RecognizedTimeFramesResponseModel>(`${this.WP_DETAILS_API}/${periodId}/recognized_working_period`).pipe(catchError(err => of(null))));
        else calls.push(of(null));

        return forkJoin(calls);
      }),
      map((data: [WorkingPeriodResponce, CustomerNotificationsResponse, ZipCodeResponseModel, RecognizedTimeFramesResponseModel]) => {
        return new ActivityReportExtended(data[0].working_period, data[1]?.customers_notifications, data[2]?.zip_code, data[3]?.recognized_working_period);
      })
    );
  }

  recognizedTimeFrames(periodId: number): Observable<any> {
    let calls = [];
    calls.push(this.http.get<WorkingPeriodResponce>(`${this.WP_DETAILS_API}/${periodId}/recognized_time_frames`));
    return forkJoin(calls);
  }

}
