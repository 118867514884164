import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivityReportExtended, VacationRequestExtended } from '@shared/factories';
import { InfoStateModel } from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class InfoStatesCollectorService {
  constructor() { }

  collectActivityReportStates(ar: ActivityReportExtended, isInternal: boolean = null): InfoStateModel[] {
    if (!isInternal) return this.collectActivityReportStatesByCustomer(ar);
    if ( isInternal) return this.collectActivityReportStatesByInternal(ar);
  }

  collectVacationRequestStates(vr: VacationRequestExtended, isInternal: boolean = null): InfoStateModel[] {
    if (!isInternal) return this.collectVacationRequestStatesByCustomer(vr);
    if ( isInternal) return this.collectVacationRequestStatesByInternal(vr);
  }

  private collectActivityReportStatesByCustomer(ar: ActivityReportExtended): InfoStateModel[] {
    let states = [];
    if (!ar.attachment_url && (ar.approvedByCustomer || ar.rejectedByCustomer)) states.push(this.mapActivityReportCustomerReview(ar, false));
    if (ar.approvedByInternal)                                                  states.push(this.mapActivityReportInternalReview(ar, false));
    if (ar.archivedBySystem || ar.deletedByInternal || ar.deletedByCustomer)    states.push(this.mapActivityReportArchiveState(  ar, false));
    return states;
  }

  private collectActivityReportStatesByInternal(ar: ActivityReportExtended): InfoStateModel[] {
    let states = [];
    if (ar.awaitingForCustomer && !ar.archived_at) states.push({ icon: 'icon-circle-time', color: 'color-orange', info: 'Kundenfreigabe erfolgt später'});
    if (!ar.attachment_url && ar.customer_review)  states.push(this.mapActivityReportCustomerReview(ar, true));
    if (ar.checked_time_at)                        states.push({ icon: 'icon-tick', info: 'Intern geprüft', at: ar.checked_time_at, by: ar.checked_time_by?.name });
    if (ar.approvedByInternal)                     states.push(this.mapActivityReportInternalReview(ar, true));
    if (ar.archivedBySystem || ar.deletedByInternal || ar.deletedByCustomer) states.push(this.mapActivityReportArchiveState(ar, true));
    return states;
  }

  private mapActivityReportCustomerReview(ar: ActivityReportExtended, isInternal: boolean = null): InfoStateModel {
    let info = 'Kundenfreigabe';

    let state: InfoStateModel = {
      icon:  ar.approvedByCustomer ? 'icon-tick'   : 'icon-cross',
      color: ar.approvedByCustomer ? 'color-green' : 'color-red',
      by:    ar.reviewed_by_customer?.name
    };

    if (ar.approvedByCustomerMobile)                info += ' am Gerät des Mitarbeiters';
    if (!isInternal && ar.rejectedByCustomerMobile) info += ' am Gerät des Mitarbeiters';
    if ( isInternal && ar.rejectedByCustomerMobile) info  = 'Klärungsbedarf / Kunde gibt nicht frei';

    if (ar.approvedByCustomer)                             info += ' erteilt';
    if ((ar.rejectedByCustomerMobile && !isInternal) ||
         ar.rejectedByCustomer && ar.customer_reviewed_at) info += ' abgelehnt';

    if      (ar.rejectedByCustomerMobile && isInternal)                  state.at = null;
    else if (ar.approvedByCustomerMobile || ar.rejectedByCustomerMobile) state.at = ar.created_at;
    else                                                                 state.at = ar.customer_reviewed_at;

    state.info = info;
    return state;
  }

  private mapActivityReportInternalReview(ar: ActivityReportExtended, isInternal: boolean = null): InfoStateModel {
    let info = '';
    let state: InfoStateModel = {
      icon:  'icon-tick',
      color: 'color-green',
      by:    ar.archived_by?.name,
      at:    ar.archived_at
    };

    if (ar.rejectedByCustomer) info = 'Nach Klärung freigegeben';
    else                       info = 'Freigegeben';

    if (!isInternal) info += ' von Tempton';

    state.info = info;
    return state;
  }

  private mapActivityReportArchiveState(ar: ActivityReportExtended, isInternal: boolean = null): InfoStateModel {
    let info = '';
    let state: InfoStateModel = {
      icon:  ar.archivedBySystem ? 'icon-warning' : 'icon-cross',
      color: ar.archivedBySystem ? 'color-orange' : 'color-red',
      by:    ar.archived_by?.name
    };

    if (ar.archivedBySystem)                 info = 'Automatisch archiviert';
    if (ar.deletedByInternal)                info = 'Gelöscht';
    if (ar.deletedByInternal && !isInternal) info += ' von Tempton';
    if (ar.deletedByCustomer)                info = 'Vom Kunden gelöscht';

    if (ar.archived_at) info += ` am`;

    if (ar.archived_at && !ar.archivedBySystem) info += ` ${formatDate(ar.archived_at, 'dd.MM.yyyy HH:mm', 'de')}`;
    if (ar.archived_at &&  ar.archivedBySystem) info += ` ${formatDate(ar.archived_at, 'dd.MM.yyyy',       'de')}`;

    if (ar.archivedBySystem) state.tooltip = 'Dieser Datensatz wurde automatisch archiviert, da dieser 3 Monate nicht bearbeitet wurde.';

    state.info = info;
    return state;
  }

  private collectVacationRequestStatesByCustomer(vr: VacationRequestExtended): InfoStateModel[] {
    let states = [];

    if (vr.assignment && !vr.createdByInternal && vr.customerReview) states.push(this.mapVacationRequestCustomerReview(vr, false));
    if (vr.internalReview) states.push(this.mapVacationRequestInternalReview(vr, false));

    return states;
  }

  private collectVacationRequestStatesByInternal(vr: VacationRequestExtended): InfoStateModel[] {
    let states         = [];
    let sortableStates = [];

    if (vr.createdByInternal) states.push({ icon: 'icon-tick', info: 'Erstellt', by: vr.createdBy?.name, at: vr.createdAt });
    if (vr.awaitingExternalReview && !vr.externalReview && !vr.archivedAt) states.push({ icon: 'icon-circle-time', color: 'color-orange', info: 'Erwarte Mitarbeiter Freigabe'});
    if (vr.createdByInternal      &&  vr.externalReview) states.push({
      icon:  vr.externalReview === 'approved' ? 'icon-tick'   : 'icon-cross',
      color: vr.externalReview === 'approved' ? 'color-green' : 'color-red',
      info:  vr.externalReview === 'approved' ? 'Akzeptiert'  : 'Abgelehnt',
      by:    vr.reviewedByExternal?.name,
      at:    vr.externalReviewedAt
    });

    if (vr.assignment && !vr.createdByInternal) {
      if (!vr.customerReview && !vr.internalReview && !vr.archivedAt) states.push({ icon: 'icon-circle-time', color: 'color-orange', info: 'Kundenfreigabe erfolgt später' });
      if ( vr.customerReview) sortableStates.push(this.mapVacationRequestCustomerReview(vr, true));
    }

    if (vr.precheckedByInternalAt) sortableStates.push({ icon: 'icon-tick', info: 'Intern geprüft', by: vr.precheckedByInternal?.name, at: vr.precheckedByInternalAt});
    if (vr.internalReview)         sortableStates.push(this.mapVacationRequestInternalReview(vr, true));

    states = [...states, ...sortableStates.sort((a, b) => a.at.getTime() - b.at.getTime())];

    if (vr.archivedBySystem) states.push({ icon: 'icon-warning', color: 'color-orange', info: 'Automatisch archiviert', at: vr.archivedAt, tooltip: 'Dieser Datensatz wurde automatisch archiviert, da dieser 3 Monate nicht bearbeitet wurde.' });
    return states;
  }

  private mapVacationRequestCustomerReview(vr: VacationRequestExtended, isInternal: boolean = null): InfoStateModel {
    let info = '';
    let customerApproved = vr.customerReview === 'approved';
    let customerRejected = vr.customerReview === 'rejected';

    let state: InfoStateModel = {
      icon:  customerApproved ? 'icon-tick'   : 'icon-cross',
      color: customerApproved ? 'color-green' : 'color-red',
      by:    vr.reviewedByCustomer?.name
    };

    if      (isInternal)                  info = 'Kundenfreigabe';
    else if (customerApproved)            info = 'Kundenbestätigung';
    else if (!vr.customerRejectionReason) info = 'Keine Bestätigung';
    else if ( vr.customerRejectionReason) info = 'Nicht bestätigt';

    if (customerApproved &&  vr.customerSignatureUrl)    info += ' am Gerät des Mitarbeiters';
    if (customerRejected && !vr.customerRejectionReason) info += ' am Gerät des Mitarbeiters';

    if (customerApproved || customerRejected && !isInternal && !vr.customerRejectionReason) info += ' erteilt';
    if (customerRejected && isInternal) info += ' abgelehnt';
    state.info = info;

    if (customerApproved && vr.customerSignatureUrl) state.at = vr.createdAt;
    else                                             state.at = vr.customerReviewedAt;

    return state;
  }

  private mapVacationRequestInternalReview(vr: VacationRequestExtended, isInternal: boolean = null): InfoStateModel {
    let info = '';
    let internalApproved = vr.internalReview === 'approved';
    let customerRejected = vr.customerReview === 'rejected';
    let externalRejected = vr.externalReview === 'rejected';
    let internalArchived = vr.internalReview === 'rejected' && vr.internalRejectionReason === 'obsolete';

    let state: InfoStateModel = {
      icon:  vr.internalReview === 'approved' ? 'icon-tick'   : 'icon-cross',
      color: vr.internalReview === 'approved' ? 'color-green' : 'color-red',
      by:    vr.reviewedByInternal?.name,
      at:    vr.internalReviewedAt
    };

    if (vr.assignment) {
      if ( vr.createdByInternal && externalRejected && internalApproved) info = 'Nach Klärung';
      if (!vr.createdByInternal && customerRejected && internalApproved) info = 'Nach Klärung';
      if (!vr.createdByInternal && !vr.customerReview)                   info = 'Ohne Bestätigung des Kunden';
    }

    info += internalApproved      ? info ? ' freigegeben' : 'Freigegeben' :
            vr.rejectedByExternal ? info ? ' archiviert'  : 'Archiviert'  :
            internalArchived      ? info ? ' archiviert'  : 'Archiviert'  :
                                    info ? ' abgelehnt'   : 'Abgelehnt';

    if (!isInternal) info += ' von Tempton';

    state.info = info;
    return state;
  }

  mapMilegeMoneyVersionHistoryItem(report): string {
    let time = `${formatDate(report.createdAt, 'HH:mm', 'de')} Uhr`;
    let date = `${formatDate(report.createdAt, 'dd.MM.yyyy', 'de')}`;
    let by   = report?.updatedBy?.name ? `von ${report.updatedBy.name}` : '';
    if (!report.event) return '';

    if (report.event.includes('edit')) {
      if (report.updatedByUserGroup === 'customer')                                return `Version durch Kundenänderungen am TN erzeugt um ${time} am ${date} ${by}`;
      else                                                                         return `Geändert: ${time} ${date} ${by}`;
    }
    else if (report.event.includes('approve'))                                     return `Genehmigt: ${time} ${date} ${by}`;
    else if (report.event.includes('reject'))                                      return `Abgelehnt: ${time} ${date} ${by}`;
    else if (report.event.includes('create') || report.event.includes('recovery')) return `Erstellt: ${time} ${date}`;
  }

}
