import { Component, Input, EventEmitter, Output } from '@angular/core';
import { formatDate } from '@angular/common';
import { Params     } from '@angular/router';
import { take, tap  } from 'rxjs/operators';

import {
  WorkingPeriodService,
  NotificationService,
  ActivityReportsCountersService,
  SessionStorageService,
  FailedErpService,
  HelperService,
  WebsocketService
} from '@shared/services';
import { TableHeaderEntryModel, TableBodyEntryModel, TableBodyCellNestedEntryModel, TableListIconModel } from '@shared/models';
import { ActivityReportSimplified } from '@shared/factories';

@Component({
  selector:     'activity-reports-table',
  templateUrl:  '../../table-entries.component.html',
  styleUrls: ['./activity-reports-table.component.sass'],
})
export class ActivityReportsTableComponent {
  @Input() rows:             ActivityReportSimplified[];
  @Input() paginationId:     string;

  @Input() dashboardOnly:    boolean;

  @Input() isInternal:       boolean;
  @Input() isCustomer:       boolean;

  @Input() extraInfoSort:    string;
  @Output() headerCallback = new EventEmitter<void>();
  constructor(
    private activityReportsCountersService: ActivityReportsCountersService,
    private workingPeriodService:           WorkingPeriodService,
    private failedErpService:               FailedErpService,
    private helperService:                  HelperService,
    private websocketService:               WebsocketService,
    private sessionStorageService:          SessionStorageService,
    private notificationService:            NotificationService
  ) { }

  headerCallbackHandler(res): void {
    this.headerCallback.emit(res);
  }

  getTableClass(): string {
    return 'activity-reports-table';
  }

  getOptionalValue(field: string): boolean {
    return this[field] || null;
  }

  prepareTableHeader(): TableHeaderEntryModel[] {
    return [
      { title: 'Mitarbeiter', class: 'name-cell',          sort_by: ['external_name']                                                                                        },
      { title: 'Pers. Nr.',   class: 'pers-num-cell',      sort_by: ['personal_number'],           internalOnly: true                                                        },
      { title: 'Eingang',     class: 'date-cell',          sort_by: ['portal_review_first',
                                                                     'created_at'],                internalOnly: true, pageOnly: ['dashboard']                               },
      { title: 'Eingang',     class: 'date-cell',          sort_by: ['archived_at'],               internalOnly: true, pageOnly: ['failed-erp']                              },

      { title: 'Erstellung',  class: 'date-cell',          sort_by: ['created_at'],                internalOnly: true, pageOnly: ['archive']                                 },
      { title: 'Überprüfung', class: 'realease-date-cell', sort_by: ['archived_at'],               internalOnly: true, pageOnly: ['archive']                                 },

      { title: 'Eingang',     class: 'date-cell',          sort_by: ['created_at'],                customerOnly: true, pageOnly: ['dashboard', 'archive']                    },
      { title: 'Tempton',     class: 'realease-date-cell', sort_by: ['archived_at'],               customerOnly: true, pageOnly: ['archive']                                 },

      { title: 'Einsatz',     class: 'title-cell',         sort_by: ['assignment_title']                                                                                     },
      { title: 'Zeitraum',    class: 'week-cell',          sort_by: ['start_date'],                                                                                          },
      { title: 'Zusatzinfo',  class: 'am-flex-center icons-cell relative', tooltipHtml: this.getExtraInfoTooltip(),    pageOnly: ['dashboard', 'archive'], 
        sortOptions: [
          { class: 'am-flex-center font-small-icon icon-holiday', sort_by: ['holiday',              'created_at']                        },
          { class: 'am-flex-center font-small-icon icon-tick',    sort_by: ['checked_time',         'created_at'], skip: this.isCustomer },
          { class: 'am-flex-center font-small-icon icon-camera',  sort_by: ['attachment',           'created_at'], active: true          },
          { class: 'am-flex-center font-small-icon icon-car',     sort_by: ['mileage_money_report', 'created_at'], skip: this.isCustomer }
        ]
      },
      { title: 'Status',      class: 'status-cell',        sort_by: ['status'],                    internalOnly: true, pageOnly: [this.dashboardOnly ? 'dashboard' : 'skip'] },
      { title: 'Status',      class: 'status-cell',        sort_by: ['export_state'],              internalOnly: true, pageOnly: ['failed-erp']                              },
      { title: 'Aktion',      class: 'action-cell',                                                                    pageOnly: ['dashboard', 'archive']                    },
      { title: 'Aktion',      class: 'action-cell failed-erp',                                                         pageOnly: ['failed-erp']                              }
    ].filter(h => h.pageOnly ? h.pageOnly.find(p => p === this.paginationId) : true);
  }

  private getExtraInfoTooltip(): string {
    return `
      <div class="web-tooltip white medium bottom left-sm">
        <span class="tooltip-title">Folgende Parameter sind in dieser Spalte sichtbar:</span>
        <ul class="tooltip-list">
          ${this.isInternal ? '<li><span class="icon-tick"></span><span>Intern geprüft</span></li>' : ''}
          <li><span class="icon-camera"></span><span>Foto Tätigkeitsnachweis</span></li>
          ${this.isInternal ? '<li><span class="icon-car"></span><span>Kilometergeld</span></li>' : ''}
          <li><span class="icon-holiday"></span><span>Feiertag(e)</span></li>
        </ul>
      </div>
    `;
  }

  private collectSplitReports(list: ActivityReportSimplified[], wp: ActivityReportSimplified, index: number): string {
    let borders = '', sideBorders = 'bl-grey';
    let topBorder    = 'btl-radius  bt-grey';
    let bottomBorder = 'bbl-radius';

    if (list[index+1]) borders += this.connectedReport(wp, list[index+1]) ? `${sideBorders} ${topBorder} `    : '';
    if (list[index-1]) borders += this.connectedReport(wp, list[index-1]) ? `${sideBorders} ${bottomBorder} ` : '';
    if (list[index+2] && this.connectedReport(list[index+1], list[index+2])) borders += 'bb-none-important'
    return borders;
  }

  private connectedReport(a: ActivityReportSimplified, b: ActivityReportSimplified): boolean {
    return a.split_child_id  && b.id === a.split_child_id ||
           a.split_parent_id && b.id === a.split_parent_id;
  }

  prepareTableBody(): TableBodyEntryModel[] {
    return this.rows.map((wp: ActivityReportSimplified, index): TableBodyEntryModel => ({
      read_only: this.isInternal && !wp.belongsToInternalLocations,
      extra_styles: this.collectSplitReports(this.rows, wp, index),
      cells: [
        { xs_label: 'Mitarbeiter', class: 'name-cell',     value: wp.external_employee.nameReverse                               },
        { xs_label: 'Pers. Nr.',   class: 'pers-num-cell', value: wp.external_employee.personal_number,       internalOnly: true },
        { xs_label: 'Eingang',     class: 'date-cell',     value: this.initialStateDateString(wp),            pageOnly: ['dashboard', this.isCustomer ? 'archive' : 'skip'],
          children: [{
            skip: !this.initialStateLabel(wp),
            class: `web-tooltip bottom short ${this.initialStateColor(wp) }`,
            children: [{
              class: "tooltip-title",
              value: this.initialStateLabel(wp)
            }]
          }],
          valueClass: `${this.isCustomer ? 'color-' : ''}${this.initialStateColor(wp)}`,
        },
        { xs_label: 'Eingang',      class: `date-cell`,          value: this.toString(wp.archived_at),                            pageOnly: ['failed-erp'] },
        { xs_label: 'Erstellung',   class: 'date-cell',          value: this.toString(wp.created_at),         internalOnly: true, pageOnly: ['archive']    },
        { xs_label: 'Überprüfung',  class: 'realease-date-cell', value: this.finalStateDateString(wp) || '-', internalOnly: true, pageOnly: ['archive']    },
        { xs_label: 'Tempton',      class: 'realease-date-cell', value: this.finalStateDateString(wp) || '-', customerOnly: true, pageOnly: ['archive'],
          children: [{
            skip: !this.finalStateDateString(wp),
            class: `web-tooltip bottom short ${this.finalState(wp)?.color}`,
            children: [{
              class: "tooltip-title",
              value: this.finalState(wp)?.label
            }]
          }],
          valueClass: `color-${this.finalState(wp)?.color}`
        },
        { xs_label: 'Einsatz',      class: 'title-cell',         value: wp.assignment.title               },
        { xs_label: 'Zeitraum',     class: 'week-cell',          value: `${wp.calendar_week}\n${wp.date}` },
        { xs_label: null,           class: 'icons-cell  xs-hide',                                                                 pageOnly: ['dashboard', 'archive'],
          children: [{
            class: 'am-flex-center font-icon gap5',
            children: this.helperService.parceTableListIconsWeb(this.getIcons(wp))
          }]},
        { xs_label: null,           class: 'status-cell xs-hide',
          children: this.helperService.parceTableListIconsWeb(this.getStatus(wp)),                            internalOnly: true, pageOnly: [this.dashboardOnly ? 'dashboard' : 'skip'] },
        { xs_label: '',             class: 'action-cell',         xs_icons: this.getIconsRowMobile(wp),                           pageOnly: ['dashboard', 'archive'],
          buttons: [{
            class: 'action-cell-button',
            label: this.getActionButtonName(wp),
            routerLink: ['/time-tracking/wp-details', wp.id],
            routerLinkState: this.getFastNavigationData()
          }]
        },
        { xs_label: '',              class: 'action-cell failed-erp', xs_icons: this.getIconsRowMobile(wp),                       pageOnly: ['failed-erp'],
          buttons: [
            { class: 'blue-thin-border custom-action-cell-button',
              label: 'Ansehen',
              routerLink: ['/time-tracking/wp-details', wp.id],
              routerLinkState: this.getFastNavigationData()
            },
            { class: 'action-cell-button',
              skip: wp.export_state !== 'failed_export' || !wp.belongsToInternalLocations,
              label: 'Erledigen',
              click: () => this.manuallyResolve(wp)
            }
          ]
        }
      ].filter(c => c.pageOnly && c.pageOnly.filter(p => p).length ? c.pageOnly.filter(p => p).find(p => p === this.paginationId) : true)
    }));
  }

  private getIcons(ar: ActivityReportSimplified): TableListIconModel[] {
    return [
      { show: !!ar.checked_time_at,         icon: 'icon-tick',    color: 'white',         tooltipTexts: ['Intern geprüft'],        internalOnly: true },
      { show: !!ar.holidays?.length,        icon: 'icon-holiday', color: 'orange-border', tooltipTexts: ar.holidays                                   },
      { show: !!ar.with_attachment,         icon: 'icon-camera',  color: 'white',         tooltipTexts: ['Foto Tätigkeitsnachweis']                   },
      { show: !!ar.mileage_money_report_id, icon: 'icon-car',     color: 'white',         tooltipTexts: ['Kilometergeld'],         internalOnly: true,
        linkTree: ['/time-tracking/wp-details', ar.id, 'wp-km', ar.mileage_money_report_id],
        linkState: this.getFastNavigationData()
      }
    ];
  }

  private getIconsRowMobile(ar: ActivityReportSimplified): TableBodyCellNestedEntryModel[] {
    let icons: any = this.getIcons(ar);
    if (this.isInternal && this.dashboardOnly) icons.splice(0, 0, { show: true, icon: `status-icon ${this.getStatusDotColor(ar)}` });
    return this.helperService.parceTableListIconsMobile(icons);
  }

  private getStatus(ar: ActivityReportSimplified): TableListIconModel[] {
    return [{ show: true, icon: `status-icon ${this.getStatusDotColor(ar)}`, color: this.getStatusDotColor(ar), tooltipTexts: [this.getStatusText(ar)].filter(t => t) }];
  }

  private getStatusText(wp: ActivityReportSimplified): string {
    if      (this.isInternal && this.paginationId === 'failed-erp') return this.getFailedERPDotText(wp);
    else if (this.isInternal && this.dashboardOnly)                 return this.getInternalStatusText(wp);
    return null;
  }

  private getStatusDotColor(wp: ActivityReportSimplified): string {
    if (this.paginationId === 'failed-erp') return this.getFailedERPDotColor(wp);
    else                                    return this.initialStateCustomer(wp).color;
  }

  private getFailedERPDotColor(wp: ActivityReportSimplified): string {
    switch (wp.export_state) {
      case 'ready_to_export':
        return 'grey';
      case 'successful_export':
        return 'green';
      case 'failed_export':
        return 'red';
      case 'resolved_manually':
        return 'blue';
      default:
        return 'grey';
    }
  }

  private getInternalStatusText(wp: ActivityReportSimplified): string {
    if (wp.approvedByCustomer)  return 'Tätigkeitsnachweise Kundenfreigabe vorhanden';
    if (wp.rejectedByCustomer)  return 'Tätigkeitsnachweise Klärungsbedarf';
    if (wp.awaitingForCustomer) return 'Warten auf TN Kundenfreigabe';
  }

  private getFailedERPDotText(wp: ActivityReportSimplified): string {
    switch (wp.export_state) {
      case 'ready_to_export':
        return 'Übertragung ausstehend';
      case 'successful_export':
        return 'Übertragung erfolgreich';
      case 'failed_export':
        return wp.export_comment;
      case 'resolved_manually':
        return 'Manuell erledigt';
      default:
        return 'Übertragung ausstehend';
    }
  }

  private getFastNavigationData(): Params {
    return ({
      data: this.rows.map(r => ({
        id: r.id,
        page: this.sessionStorageService.pageNumberValue
      }))
    });
  }

  private initialStateDateString(wp: ActivityReportSimplified): string {
    return formatDate(wp.customer_reviewed_at || wp.created_at, 'dd.MM.yyyy', 'de');
  }

  private finalStateDateString(wp: ActivityReportSimplified): string {
    return wp.archived_at ? formatDate(wp.archived_at, 'dd.MM.yyyy', 'de') : null;
  }

  private initialStateColor(wp: ActivityReportSimplified): string {
    if (this.isInternal) return this.initialStateInternal(wp)?.color;
    if (this.isCustomer) return this.initialStateCustomer(wp)?.color;
  }

  private initialStateLabel(wp: ActivityReportSimplified): string {
    if (this.isInternal) return this.initialStateInternal(wp)?.label;
    if (this.isCustomer) return this.initialStateCustomer(wp)?.label;
  }

  private initialStateCustomer(wp: ActivityReportSimplified): { color: string, label?: string } {
    if (wp.approvedByCustomer)  return { color: 'green',  label: 'Von Ihnen freigegeben'  };
    if (wp.rejectedByCustomer)  return { color: 'red',    label: 'Von Ihnen abgelehnt'    };
    if (wp.awaitingForCustomer) return { color: 'orange', label: 'Ihre Freigabe erwartet' };
    return { color: 'grey' };
  }

  private initialStateInternal(wp: ActivityReportSimplified): { color: string, label?: string } {
    if (wp.customer_reviewed_at && wp.approvedByCustomer)        return { color: 'bold grey', label: 'Von Kunden freigegeben' };
    if (wp.customer_reviewed_at && wp.rejectedByCustomerPortals) return { color: 'bold grey', label: 'Von Kunden abgelehnt'   };
  }

  private finalState(wp: ActivityReportSimplified) {
    if (wp.approvedByInternal) return { color: 'green', label: 'Von Tempton überprüft'  };
    if (wp.rejectedByInternal) return { color: 'red',   label: 'Von Tempton archiviert' };
  }

  private getActionButtonName(wp: ActivityReportSimplified): string {
    if (this.isCustomer) return this.getActionButtonNameCustomer(wp);
    if (this.isInternal) return this.getActionButtonNameInternal(wp);
  }

  private getActionButtonNameCustomer(wp: ActivityReportSimplified): string {
    return wp.awaitingForCustomer ? 'Bearbeiten' : 'Ansehen';
  }
  private getActionButtonNameInternal(wp: ActivityReportSimplified): string {
    return !wp.belongsToInternalLocations || wp.awaitingForCustomer || wp.archived_at ? 'Ansehen' : 'Bearbeiten';
  }

  manuallyResolve(wp: ActivityReportSimplified): void {
    this.notificationService.wait();
    this.workingPeriodService.manuallyResolveWorkingPeriod(wp.id).pipe(
      take(1),
      tap(() => this.sessionStorageService.failedResolved = 'ar')
    ).subscribe(
      res => this.reloadCounters(),
      error => this.notificationService.alert(error.message || error)
    );
  }

  private reloadCounters(): void {
    if (!this.websocketService.wsOnline()) {
      this.activityReportsCountersService.reloadCounters();
      this.failedErpService.forceReload();
    }
  }

  private toString(date: Date): string {
    return formatDate(date, 'dd.MM.yyyy', 'de');
  }

}
