import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { formatDate } from '@angular/common';
import { ActivityReportExtended, Assignment, EBS, PhotoDocument, AssignmentExtended, ActivityReportBasicWithTimeFrames, VacationRequestExtended, MileageMoneyReportBasic, StandaloneMileageMoney, ActivityReportMileageMoney, ActivityReportMileageMoneyExtended } from '@shared/factories';

@Component({
  selector:      'details-header',
  templateUrl: './details-header.component.html',
  styleUrls:  ['./details-header.component.sass'],
  host: {'class': 'am-flex-align width-full-mobile gap-m'}
})
export class DetailsHeaderComponent implements OnChanges {
  @Input() entry:         any;
  @Input() weeks:         any;

  @Input() isInternal:    boolean;
  @Input() readOnly:      boolean;

  @Input() validPhotoTn:  boolean;
  @Input() mileageMoney:  string;

  headerItems: any[] = [];
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.entry?.firstChange || changes?.entry?.currentValue?.id !== changes?.entry?.previousValue?.id) this.prepareHeader();
  }

  private prepareHeader(): any {
    this.headerItems = [];
    switch (this.entry.constructor) {
      case ActivityReportExtended:
      case ActivityReportBasicWithTimeFrames:
        return this.prepareActivityReportHeader(this.entry);
      case AssignmentExtended:
        return this.prepareAssignmentHeader(this.entry);
      case EBS:
        return this.prepareEBSHeader(this.entry);
      case PhotoDocument:
        return this.preparePhotoDocumentHeader(this.entry);
      case VacationRequestExtended:
        return this.prepareVacationRequestHeader(this.entry);
      case StandaloneMileageMoney:
      case ActivityReportMileageMoneyExtended:
        return this.prepareMileageMoneyHeader(this.entry);
    }
  }

  private prepareActivityReportBasicHeader(entry: ActivityReportExtended) {
    return [
      { icon: 'icon-user',        label: 'Personalnummer', value: entry.external_employee.personal_number },
      { icon: 'icon-circle-time', label: 'Zeitraum', childrenClass: 'am-flex-column', children: [
        { class: 'crossed', skip: !this.checkIfValidTimePeriod(entry) || !entry.original_end_date, children: [
          { class: 'word-no-wrap', value: formatDate(entry.start_date,        'EEEE dd.MM.yyyy','de') },
          { class: 'pl5 pr5',      value: '-'                                                         },
          { class: 'word-no-wrap', value: entry.id ? formatDate(entry.original_end_date, 'EEEE dd.MM.yyyy','de') : null }
        ]},
        { class: `${entry.assignment.active && !this.checkIfValidTimePeriod(entry) ? 'color-red' : entry.original_end_date ? 'color-orange': ''}`, children: [
          { class: 'word-no-wrap', value: formatDate(entry.start_date, 'EEEE dd.MM.yyyy','de')        },
          { class: 'pl5 pr5',      value: '-'                                                         },
          { class: 'word-no-wrap', value: formatDate((!this.checkIfValidTimePeriod(entry) ? entry.original_end_date || entry.end_date : entry.end_date), 'EEEE dd.MM.yyyy','de') },
        ]}
      ].filter(i => !i.skip)},
      { icon: 'icon-note', label: 'Einsatz',       value: entry.assignment.title                  },
      { icon: 'icon-date', label: 'Kalenderwoche', value: formatDate(entry.start_date, 'ww','de') },
    ];
  }

  private prepareActivityReportHeader(entry: ActivityReportExtended): void {
    this.headerItems = [...this.prepareActivityReportBasicHeader(entry),
      { icon: 'icon-circle-info', label: 'Status',    valueClass: `status-dot ${this.getExportState(entry.export_state)}`,    skip: !this.isInternal || !entry.archived_at },
      { icon: 'icon-comment',     label: 'Kommentar', value: this.getExportComment(entry.export_state, entry.export_comment), skip: !this.isInternal || !entry.archived_at },
    ].filter((i: any) => !i.skip);
  }

  private prepareMileageMoneyHeader(entry: ActivityReportMileageMoneyExtended | StandaloneMileageMoney): void {
    this.headerItems = [
      { icon: 'icon-user',          label: 'Personalnummer', value: entry.externalEmployee.personal_number },
      { icon: 'icon-circle-time',   label: 'Zeitraum', children: [
        { class: 'word-no-wrap',  value: formatDate(entry.startDate, 'EEEE dd.MM.yyyy','de')  },
        { class: 'pl-xxs pr-xxs', value: '-'                                                  },
        { class: 'word-no-wrap',  value: formatDate(entry.endDate,    'EEEE dd.MM.yyyy','de') }
      ]},
      { icon: 'icon-note',          label: 'Einsatz',        value: entry.assignment.title                 },
      { icon: 'icon-date',          label: 'Kalenderwoche',  value: formatDate(entry.startDate, 'ww','de') },
      { icon: 'icon-licence-plate', label: 'Kennzeichen',    value: (entry as ActivityReportMileageMoneyExtended).licensePlateMapped, skip: !(entry as ActivityReportMileageMoneyExtended).licensePlateMapped }
    ].filter((i: any) => !i.skip);
  }

  private prepareAssignmentHeader(entry: Assignment): void {
    this.headerItems = [
      { icon: 'icon-note', label: 'Einsatz',       value: entry.title                                                                                                   },
      { icon: 'icon-date', label: 'Einsatz seit',  value: formatDate(entry.starts_at, 'dd.MM.yyyy','de')                                                                },
      { icon: 'icon-date', label: 'Einsatz endet', value: formatDate(entry.ends_at,   'dd.MM.yyyy','de'), class: this.checkExpiryDate(entry.ends_at) ? 'color-red' : '' },
    ];
  }

  private prepareEBSHeader(entry: EBS): void {
    this.headerItems = [
      { icon: 'icon-note',    label: 'Einsatz',     value: entry.title },
      { icon: 'icon-comment', label: 'Einspiel ID', value: entry.id    }
    ];
    if (entry.assignment_provisioned_on) this.headerItems.push({ icon: 'icon-circle-info', label: 'Bereitstellungsdatum', value: formatDate(entry.assignment_provisioned_on, 'dd.MM.yyyy', 'de') });
  }

  private preparePhotoDocumentHeader(entry: PhotoDocument): void {
    this.headerItems = [
      { icon: 'icon-user',    label: 'Personalnummer', value: entry.externalEmployee.personal_number               },
      { icon: 'icon-date',    label: 'Eingang',        value: formatDate(entry.createdAt, 'dd.MM.yyyy HH:mm','de') },
      { icon: 'icon-comment', label: 'zvoove Status',  value: entry.releasedToZvooveStatus                         }
    ];
  }

  private prepareVacationRequestHeader(entry: VacationRequestExtended): void {
    let multipleAssignments = entry.involvedAssignments?.length > 1;
    this.headerItems = [
      { icon: 'icon-user',           label: 'Personalnummer',                     value: entry.externalEmployee?.personal_number                                                                        },
      { icon: 'icon-note',           label: multipleAssignments ? '' : 'Einsatz', value: multipleAssignments ? 'Es liegen mehrere Einsätze vor' : entry.assignment?.title || 'Kein Einsatz vorliegend',
        class: 'tooltip-hover', childrenClass: 'am-flex-column web-tooltip font-s white left-10 top-20 width-max-content', children: multipleAssignments ? entry.involvedAssignments?.map(a => (
          { class: 'am-flex-align', value: a.title }
        )) : null 
      },
      { icon: 'icon-date',            label: 'Erstellungsdatum', value: formatDate(entry.createdAt, 'dd.MM.yyyy HH:mm','de')                                                                     },
      { icon: 'icon-circle-question', label: 'Grund',            value: entry.reasonMapped,                                            skip: !this.isInternal || !entry.reason                   },
      { icon: 'icon-circle-question', label: 'Grund',            value: entry.externalComment,                                         skip: !this.isInternal ||  entry.leaveType !== 'unpaid'   },
      { icon: 'icon-circle-info',     label: 'Initiator',        value: entry.initiatorMapped,                                         skip: !this.isInternal ||  entry.leaveType !== 'overtime' },
      { icon: 'icon-comment',         label: 'Exportstatus',     value: this.getExportComment(entry.exportState, entry.exportComment), skip: !this.isInternal || !entry.internalReview           },
    ].filter((i: any) => !i.skip);
  }

  private checkIfValidTimePeriod(entry: ActivityReportExtended): boolean {
    return entry.assignment.ends_at.getTime() >= entry.start_date.getTime();
  }

  private getExportComment(exportState: string, exportComment: string): string {
    switch (exportState) {
      case 'ready_to_export':
        return 'Übertragung ausstehend';
      case 'successful_export':
        return 'Übertragung erfolgreich';
      case 'failed_export':
        return exportComment;
      case 'resolved_manually':
        return 'Manuell erledigt';
      default:
        return 'Übertragung ausstehend';
    }
  }

  private getExportState(importState: string): string {
    switch (importState) {
      case 'ready_to_export':
        return 'grey';
      case 'successful_export':
        return 'green';
      case 'failed_export':
        return 'red';
      case 'resolved_manually':
        return 'blue';
      default:
        return 'grey';
    }
  }

  private checkExpiryDate(endDate: Date): boolean {
    return (endDate.getTime() - new Date().getTime()) < (15 * 24 * 60 * 60 * 1000);
  }

}
